import { FC } from 'react';

const RemoteFirst: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 301" width={440} height={301} fill="none">
      <g clipPath="url(#rfa)">
        <path
          fill="url(#rfb)"
          d="M30.482 27.088c.46 0 .91.02 1.36.054a24.561 24.561 0 0 1-.179-2.908C31.663 10.851 42.505 0 55.883 0c13.376 0 24.094 10.732 24.213 24.012 2.6-1.766 5.74-2.8 9.12-2.8 7.797 0 14.308 5.49 15.889 12.815 1.409-.4 2.897-.618 4.435-.618 8.977 0 16.254 7.28 16.254 16.261 0 8.161-6.006 14.917-13.842 16.084v.178h-81.47c-10.723 0-19.414-8.695-19.414-19.422s8.691-19.422 19.414-19.422Z"
        />
        <path
          fill="url(#rfc)"
          d="M407.308 99.573c-.539 0-1.073.025-1.602.065.134-1.123.213-2.266.213-3.428 0-15.762-12.769-28.537-28.525-28.537-15.755 0-28.376 12.637-28.519 28.275a19.053 19.053 0 0 0-10.743-3.299c-9.18 0-16.848 6.464-18.711 15.095a19.145 19.145 0 0 0-5.221-.727c-10.574 0-19.146 8.576-19.146 19.155 0 9.609 7.074 17.567 16.299 18.942v.213h95.955c12.626 0 22.864-10.243 22.864-22.874 0-12.632-10.238-22.875-22.864-22.875v-.005Z"
        />
        <path
          fill="#AAA"
          d="M110.91 133.491H83.048a1.8 1.8 0 0 0-1.8 1.801v18.25a1.8 1.8 0 0 0 1.8 1.8h27.862a1.8 1.8 0 0 0 1.799-1.8v-18.25a1.8 1.8 0 0 0-1.799-1.801Z"
        />
        <path
          fill="#fff"
          d="M96.979 145.322c-.84 0-1.68-.243-2.412-.727l-12.888-8.537a.741.741 0 1 1 .82-1.236l12.888 8.536a2.885 2.885 0 0 0 3.194 0l12.888-8.536a.742.742 0 1 1 .82 1.236l-12.888 8.537a4.373 4.373 0 0 1-2.417.727h-.005Z"
        />
        <path
          fill="#2B6FDF"
          d="M271.265 66.417h-105.19c-4.827 0-8.74 3.915-8.74 8.744v61.125c0 4.829 3.913 8.744 8.74 8.744h105.19c4.827 0 8.74-3.915 8.74-8.744V75.161c0-4.83-3.913-8.744-8.74-8.744Z"
        />
        <path
          fill="#C46A43"
          d="m146.642 105.874-2.423-3.061s-1.834-8.878-3.292-9.907c-1.458-1.028-2.141.42-1.453 2.988.687 2.566.613 3.758.613 3.758s-8.034-1.815-11.094-2.616c-3.06-.801-8.572-1.355-8.34.623.228 1.984 5.127 1.746 8.572 3.091 0 0-10.786-3.234-11.019-1.103-.227 2.132 10.026 4.036 10.026 4.036s-10.713-3.323-10.634-.87c.074 2.453 7.267 3.249 9.566 3.882 0 0-7.608-1.943-6.961.396.44 1.602 6.313 1.543 9.453 3.131 3.139 1.582 11.286 2.893 11.286 2.893l5.71-7.236-.01-.005ZM289.833 106.517l2.418-3.061s1.834-8.878 3.292-9.907c1.458-1.028 2.141.42 1.453 2.988-.687 2.566-.613 3.758-.613 3.758s8.034-1.815 11.094-2.616c3.06-.801 8.572-1.355 8.34.623-.228 1.984-5.127 1.741-8.572 3.091 0 0 10.786-3.234 11.019-1.103.227 2.132-10.026 4.036-10.026 4.036s10.713-3.323 10.634-.87c-.074 2.453-7.267 3.249-9.566 3.882 0 0 7.608-1.943 6.961.396-.44 1.602-6.313 1.543-9.453 3.131-3.139 1.582-11.286 2.893-11.286 2.893l-5.71-7.236.015-.005Z"
        />
        <path
          fill="#1E1E1E"
          d="M208.6 70.764a5.518 5.518 0 0 0 5.517-5.52 5.518 5.518 0 1 0-11.034 0c0 3.049 2.47 5.52 5.517 5.52Z"
        />
        <path
          fill="#1E1E1E"
          d="M205.006 80.483s-4.726-7.444 2.986-11.93c0 0 2.2-7.794 14.618-6.058 12.418 1.74 10.253 15.757 8.933 18.111 0 0 12.354 9.254 6.545 24.452h-38.105s-6.521-14.254 5.018-24.58l.005.005Z"
        />
        <path
          fill="#8CCCFF"
          d="M218.67 100.651h-16.546a19.653 19.653 0 0 0-12.28 4.308l-20.348 14.615-23.032-14.516-6.575 8.052 24.624 19.748a9.687 9.687 0 0 0 9.635 1.45l19.082-7.563 25.44-2.235"
        />
        <path
          fill="#8CCCFF"
          d="M218.67 100.651h16.546c4.464 0 8.795 1.519 12.28 4.308l20.348 14.615 23.032-14.516 6.575 8.052-24.624 19.748a9.687 9.687 0 0 1-9.635 1.45l-19.082-7.563-25.44-2.235"
        />
        <path
          fill="#C46A43"
          d="M208.085 88.015a4.01 4.01 0 0 1-4.009-4.01 4.01 4.01 0 0 1 4.009-4.012v8.022ZM229.254 79.993a4.01 4.01 0 0 1 4.009 4.011 4.01 4.01 0 0 1-4.009 4.011v-8.022Z"
        />
        <path
          fill="#B75D3D"
          d="M218.67 108.08a5.687 5.687 0 0 1-5.686-5.688v-7.166h11.371v7.166a5.687 5.687 0 0 1-5.685 5.688Z"
        />
        <path
          fill="#C46A43"
          d="M229.254 79.118v8.427c0 5.846-4.736 10.59-10.584 10.59-5.843 0-10.584-4.739-10.584-10.59V72.777s7.213 7.958 21.168 6.34Z"
        />
        <path fill="#CACACA" d="M244.05 145.03h-50.761l-4.138-31.915h59.042l-4.143 31.915Z" />
        <path
          fill="#fff"
          d="M218.67 132.201c2.567 0 4.647-1.402 4.647-3.131 0-1.729-2.08-3.131-4.647-3.131-2.566 0-4.647 1.402-4.647 3.131 0 1.729 2.081 3.131 4.647 3.131Z"
        />
        <path fill="#D9EDFF" d="M212.984 81.393a4.14 4.14 0 0 0 0 8.28 4.14 4.14 0 0 0 0-8.28Z" />
        <path
          fill="#1E1E1E"
          d="M212.985 80.898a4.636 4.636 0 0 0-4.632 4.634 4.636 4.636 0 0 0 4.632 4.635 4.636 4.636 0 0 0 4.632-4.635 4.636 4.636 0 0 0-4.632-4.634Zm0 8.774a4.144 4.144 0 0 1-4.138-4.14 4.14 4.14 0 0 1 4.138-4.14 4.14 4.14 0 0 1 0 8.28Z"
        />
        <path
          fill="#D9EDFF"
          d="M224.355 81.393a4.144 4.144 0 0 0-4.138 4.14 4.14 4.14 0 0 0 4.138 4.139 4.14 4.14 0 0 0 0-8.28Z"
        />
        <path
          fill="#1E1E1E"
          d="M224.355 80.898a4.636 4.636 0 0 0-4.632 4.634 4.636 4.636 0 0 0 4.632 4.635 4.636 4.636 0 0 0 4.632-4.635 4.636 4.636 0 0 0-4.632-4.634Zm0 8.774a4.144 4.144 0 0 1-4.138-4.14 4.14 4.14 0 0 1 4.138-4.14 4.14 4.14 0 0 1 0 8.28Z"
        />
        <path
          fill="#1E1E1E"
          d="M219.971 85.78h-2.601a.245.245 0 0 1-.247-.248c0-.138.109-.247.247-.247h2.601c.138 0 .247.109.247.247a.245.245 0 0 1-.247.248Z"
        />
        <path
          fill="#000"
          d="M217.37 95.142h-.02c-3.124-.242-3.816-1.969-3.846-2.038a.25.25 0 0 1 .143-.321.248.248 0 0 1 .317.143c.024.064.633 1.504 3.421 1.726a.248.248 0 0 1-.02.495l.005-.005Z"
        />
        <path
          fill="#1E1E1E"
          d="M333.342 158.636c10.088 0 18.266-8.182 18.266-18.275 0-10.093-8.178-18.274-18.266-18.274-10.089 0-18.267 8.181-18.267 18.274 0 10.093 8.178 18.275 18.267 18.275Z"
        />
        <path
          fill="#fff"
          d="M340.361 129.337h-14.04c-.666 0-1.206.54-1.206 1.207v19.639c0 .667.54 1.207 1.206 1.207h14.04c.666 0 1.207-.54 1.207-1.207v-19.639c0-.667-.541-1.207-1.207-1.207Z"
        />
        <path
          fill="#2B6FDF"
          d="M334.81 132.651h-7.183v.494h7.183v-.494ZM338.844 136.874h-11.217v.495h11.217v-.495ZM335.364 138.912h-7.737v.495h7.737v-.495ZM338.844 142.132h-11.217v.494h11.217v-.494ZM335.364 144.169h-7.737v.495h7.737v-.495ZM338.676 148.245h-3.871v.494h3.871v-.494Z"
        />
        <path
          fill="#1E1E1E"
          d="M123.64 87.085c11.41 0 20.659-9.253 20.659-20.668s-9.249-20.669-20.659-20.669c-11.41 0-20.66 9.254-20.66 20.669 0 11.415 9.25 20.668 20.66 20.668Z"
        />
        <path
          fill="#3EAAFF"
          d="M311.086 87.085c11.41 0 20.659-9.253 20.659-20.668s-9.249-20.669-20.659-20.669c-11.41 0-20.659 9.254-20.659 20.669 0 11.415 9.249 20.668 20.659 20.668Z"
        />
        <path
          fill="#fff"
          d="M313.379 56.347h-13.145v20.144h13.145V56.347ZM321.937 63.232h-8.557v13.254h8.557V63.232Z"
        />
        <path
          stroke="#0A0A0A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M304.101 60.076v2.087M304.101 65.373v2.087M304.101 70.957v2.087M316.415 66.417v2.087M318.812 71.18v2.087M309.944 65.373v2.087"
        />
        <path
          fill="#8CCCFF"
          d="M148.827 195.788h-128c-4.996 0-9.047 4.053-9.047 9.051v86.863c0 4.999 4.05 9.051 9.047 9.051h128c4.996 0 9.047-4.052 9.047-9.051v-86.863c0-4.998-4.051-9.051-9.047-9.051Z"
        />
        <path
          fill="#fff"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M68.538 237.526a5.177 5.177 0 0 0 5.176-5.178 5.178 5.178 0 0 0-5.176-5.179 5.178 5.178 0 0 0-5.176 5.179 5.177 5.177 0 0 0 5.176 5.178Z"
        />
        <path
          fill="#1E1E1E"
          d="M67.698 221.234c4.973-9.392-3.13-13.566-8.904-11.558-5.774 2.008-8.181 6.741-13.797 8.27-5.616 1.523-13.397 3.13-15.162 9.792-1.765 6.662 4.251 14.204 11.632 18.943 0 0 2.052 1.523 5.967-.327 3.915-1.855 6.713-8.076 6.713-8.076"
        />
        <path
          fill="#FF8438"
          d="M52.536 231.828s1.285 2.087 2.808.643c1.522-1.444.563-2.967.48-5.138 0 0 8.824-1.044 11.874-6.099l.643 11.747s.158 11.771 0 16.504c-.159 4.733-6.338 3.773-9.225 2.651l-4.01 6.741s-7.538-5.821-16.126-8.166l2.487-4.03s7.608-.065 9.625-7.127c0 0-4.172.38-5.294-4.595-1.123-4.975 4.33-6.182 6.738-3.131Z"
        />
        <path
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m52.535 231.828 19.893-1.849"
        />
        <path
          fill="#444"
          d="M38.975 270.351c6.18-5.94 16.126-2.651 19.092.722 2.966 3.373 20.536 29.675 20.536 29.675H19.349a7.576 7.576 0 0 1-7.574-7.577v-16.163s1.844-3.289 7.223-12.038c5.374-8.749 13.397-8.749 13.397-8.749s1.607-2.809 3.208-5.519c1.607-2.711 4.109-1.197 4.109-1.197l15.972 8.378s2.225.742.54 4.273c-1.686 3.531-1.444 2.488-1.444 2.488l2.289 5.489"
        />
        <path
          fill="#000"
          d="M48.605 301a.251.251 0 0 1-.207-.114c-.025-.034-2.428-3.694-4.48-6.301-1.996-2.537-4.948-3.036-4.977-3.041a.25.25 0 0 1-.208-.282.25.25 0 0 1 .282-.208c.128.02 3.169.529 5.29 3.22 2.066 2.626 4.478 6.301 4.503 6.335a.248.248 0 0 1-.07.342.226.226 0 0 1-.133.039v.01Z"
        />
        <path
          fill="#1E1E1E"
          d="m97.581 277.938-23.047 9.511 46.757 13.304h6.916l21.242-11.41-51.868-11.405ZM160.4 256.265l-10.95 33.078-51.868-11.405 12.755-29.665 50.063 7.992Z"
        />
        <path fill="#959595" d="m101.19 275.89 45.852 9.872 9.507-26.885-44.557-7.8-10.802 24.813Z" />
        <path fill="#fff" d="M134.288 216.017H90.844v51.851h43.444v-51.851Z" />
        <path
          fill="#FF8438"
          d="M110.772 292.221s4.34-5.974 6.584-7.26c2.245-1.286 8.024-3.531 9.067-1.444 0 0 2.729-.643 3.05 1.043 0 0 2.65-.4 2.566 1.202l-.964 1.677s1.285-.396 1.443 1.211c.159 1.608-.8 1.687-2.728 3.373-1.923 1.687-6.981 8.73-6.981 8.73h-2.966l-9.076-8.532h.005Z"
        />
        <path
          fill="#000"
          d="M119.848 286.895a.243.243 0 0 1-.203-.109.241.241 0 0 1 .064-.341c.163-.114 4.089-2.765 6.679-3.171a.247.247 0 0 1 .282.208.246.246 0 0 1-.208.282c-2.481.386-6.436 3.061-6.476 3.091a.24.24 0 0 1-.138.045v-.005ZM122.414 288.819a.253.253 0 0 1-.198-.099.252.252 0 0 1 .05-.347c.177-.133 4.399-3.244 7.138-4.05a.244.244 0 0 1 .307.168.245.245 0 0 1-.169.307c-2.654.781-6.94 3.941-6.98 3.976a.23.23 0 0 1-.148.05v-.005ZM124.5 291.099a.252.252 0 0 1-.208-.114.243.243 0 0 1 .075-.341c.182-.119 4.553-2.963 6.659-3.443a.249.249 0 0 1 .108.485c-2.017.46-6.451 3.343-6.496 3.373a.258.258 0 0 1-.133.04h-.005Z"
        />
        <path fill="#444" d="m90.844 300.753 18.894-8.73s7.945.841 10.11 8.73H90.844Z" />
        <path
          fill="#000"
          d="M56.708 249.341a.253.253 0 0 1-.173-.069c-3.45-3.398-4.785-8.18-4.8-8.225a.243.243 0 0 1 .173-.301.245.245 0 0 1 .302.173c.014.044 1.315 4.703 4.666 8.002a.245.245 0 0 1 0 .351.249.249 0 0 1-.178.074l.01-.005ZM68.252 246.285c-.628 0-2.878-.203-5.077-2.908a.249.249 0 0 1 .034-.346.244.244 0 0 1 .346.034c2.363 2.898 4.795 2.72 4.82 2.72a.251.251 0 0 1 .267.223.249.249 0 0 1-.222.267c-.015 0-.075.005-.168.005v.005Z"
        />
        <path fill="#3EAAFF" d="M128.988 221.234H95.535v33.469h33.453v-33.469Z" />
        <path
          fill="#FF8B19"
          d="M107.113 224.786s-6.763.118-5.196 7.883c0 0-2.768 3.433.183 6.563 0 0-6.56 1.662-6.56 7.933v7.538h33.453v-7.538s-1.562-6.306-7.282-7.992c0 0 2.289-3.25.183-6.321 0 0-.138-13.784-14.781-8.066Z"
        />
        <path
          fill="#FFA778"
          d="M105.081 239.049a2.387 2.387 0 0 1-2.388-2.389 2.387 2.387 0 0 1 2.388-2.388v4.777ZM117.678 234.276a2.387 2.387 0 0 1 2.387 2.389 2.386 2.386 0 0 1-2.387 2.389v-4.778Z"
        />
        <path
          fill="#1E1E1E"
          d="M125.741 254.703H97.019c0-4.956 4.014-8.972 8.967-8.972h10.792c4.954 0 8.968 4.016 8.968 8.972h-.005Z"
        />
        <path
          fill="#FF8D5C"
          d="M111.38 250.988a4.274 4.274 0 0 1-4.272-4.273v-3.378h8.543v3.378a4.273 4.273 0 0 1-4.271 4.273Z"
        />
        <path
          fill="#FFA778"
          d="M117.677 233.752v5.015a6.297 6.297 0 0 1-6.298 6.301 6.297 6.297 0 0 1-6.298-6.301v-8.788s4.291 4.733 12.596 3.773Z"
        />
        <path
          fill="#1E1E1E"
          d="M157.039 230.513c10.511 0 19.033-8.525 19.033-19.041 0-10.517-8.522-19.042-19.033-19.042-10.512 0-19.033 8.525-19.033 19.042 0 10.516 8.521 19.041 19.033 19.041Z"
        />
        <path
          fill="#3EAAFF"
          d="M418.901 195.788H293.165a7.892 7.892 0 0 0-7.89 7.894v89.177a7.892 7.892 0 0 0 7.89 7.894h125.736a7.892 7.892 0 0 0 7.89-7.894v-89.177a7.892 7.892 0 0 0-7.89-7.894Z"
        />
        <path
          fill="#FF8B19"
          d="M364.867 204.805s-3.861.519-7.589-1.405c-3.727-1.924-13.451-6.87-18.909 0-5.458 6.87-.41 14.462 2.788 18.725l29.568 38.503 27.165 5.331s11.454-4.664 7.193-17.988c-4.261-13.324-18.375-10.569-19.176-20.806-.801-10.238-5.591-23.958-21.04-22.36Z"
        />
        <path
          fill="#1E1E1E"
          d="m360.794 245.375-19.685 6.731a31.158 31.158 0 0 0-17.446 14.882l-17.921 33.76h114.262l-14.297-32.514a33.357 33.357 0 0 0-17.318-17.201l-19.309-8.334-8.281 2.671-.005.005Z"
        />
        <path
          fill="#FFA778"
          d="m351.084 246.77 1.172 3.655c1.844 5.766 8.616 8.442 13.782 5.282.079-.05.164-.099.243-.154 4.296-2.74 5.477-8.516 2.798-12.849l-5.567-9.001-12.423 13.072-.005-.005Z"
        />
        <path
          fill="#1E1E1E"
          d="m351.084 246.77.865 2.7s8.928-1.845 13.165-13.176l-1.602-2.591-12.423 13.072-.005-.005Z"
          opacity={0.15}
        />
        <path
          fill="#2B6FDF"
          d="M287.253 229.568c10.443 0 18.909-8.47 18.909-18.917 0-10.448-8.466-18.918-18.909-18.918s-18.909 8.47-18.909 18.918c0 10.447 8.466 18.917 18.909 18.917Z"
        />
        <path
          fill="#FFA778"
          d="M347.154 209.869s4.128 10.658 16.112 11.727l3.46.667-2.798 10.525s-6.125 14.254-14.915 14.254c-8.789 0-7.193-11.727-7.722-21.45-.529-9.724.267-12.389 5.859-15.723h.004Z"
        />
        <path
          fill="#103675"
          d="M369.959 235.182c4.248 0 7.692-4.325 7.692-9.659 0-5.335-3.444-9.66-7.692-9.66s-7.692 4.325-7.692 9.66c0 5.334 3.444 9.659 7.692 9.659Z"
        />
        <path
          fill="#1850AF"
          d="M373.355 235.182c4.249 0 7.693-4.325 7.693-9.659 0-5.335-3.444-9.66-7.693-9.66-4.248 0-7.692 4.325-7.692 9.66 0 5.334 3.444 9.659 7.692 9.659Z"
        />
        <path
          fill="#000"
          d="M368.729 216.239a.245.245 0 0 1-.248-.247v-15.015c0-4.328-3.52-7.849-7.845-7.849h-.722c-4.326 0-7.845 3.521-7.845 7.849a.245.245 0 0 1-.248.247.245.245 0 0 1-.247-.247c0-4.6 3.743-8.344 8.34-8.344h.722c4.597 0 8.34 3.744 8.34 8.344v15.015a.245.245 0 0 1-.247.247Z"
        />
        <path
          fill="#000"
          d="M371.961 216.239a.245.245 0 0 1-.247-.247v-15.015c0-4.328-3.52-7.849-7.846-7.849-4.726 0-8.572 3.847-8.572 8.576v.593a.244.244 0 0 1-.247.247.244.244 0 0 1-.247-.247v-.593c0-5.001 4.068-9.071 9.066-9.071 4.598 0 8.34 3.744 8.34 8.344v15.015a.245.245 0 0 1-.247.247ZM348.84 239.826a.245.245 0 0 1-.024-.49c.044 0 4.647-.48 6.001-4.233a.245.245 0 0 1 .317-.149.249.249 0 0 1 .148.317c-1.463 4.055-6.372 4.555-6.417 4.56h-.025v-.005Z"
        />
        <path
          fill="#7A7A7A"
          d="M264.537 252.066h65.78a3.46 3.46 0 0 1 3.312 2.468l13.753 46.214h-71.583l-13.565-45.59a2.409 2.409 0 0 1 2.308-3.096l-.005.004Z"
        />
        <path
          fill="#fff"
          d="M308.873 279.688c1.601-1.858.874-5.11-1.624-7.265-2.497-2.155-5.82-2.396-7.421-.538-1.601 1.857-.874 5.11 1.623 7.265 2.498 2.155 5.821 2.395 7.422.538Z"
        />
        <path fill="#1E1E1E" d="m416.538 300.753-33.458-13.947-7.762 11.726 1.533 2.221h39.687Z" />
        <path fill="#2B6FDF" d="M371.056 300.753h-16.072l-1.043-29.027h18.158l-1.043 29.027Z" />
        <path
          fill="#fff"
          d="M372.085 267.557h-18.124a1.858 1.858 0 0 0-1.858 1.859v.45a1.86 1.86 0 0 0 1.858 1.86h18.124a1.86 1.86 0 0 0 1.858-1.86v-.45a1.858 1.858 0 0 0-1.858-1.859Z"
        />
        <path
          fill="#FFA778"
          d="m383.554 288.606-2.076-7.874a8.416 8.416 0 0 0-7.683-6.271 21.426 21.426 0 0 0-2.808.025c-4.394.356-12.714 2.532-12.517 4.728.198 2.196 3.065 1.4 3.065 1.4s-2.397 1.399-2.066 2.532c.331 1.132 2.862.935 2.862.935s-2.219 1.8-1.542 2.932c.677 1.133 2.541.535 2.541.535s-1.399 1.533.801 2.73c2.195 1.196 4.592-.935 7.257-.535 2.664.401 1.597 6.573 3.93 8.794 2.328 2.221 9.477-1.266 8.236-9.926v-.005Z"
        />
        <path
          fill="#000"
          d="M361.536 280.861a.246.246 0 0 1-.228-.154.248.248 0 0 1 .134-.326c.286-.119 7.005-2.953 9.561-2.819a.242.242 0 0 1 .232.262c-.005.139-.119.237-.262.233-2.452-.124-9.274 2.749-9.343 2.779a.215.215 0 0 1-.094.02v.005ZM362.336 284.323a.249.249 0 0 1-.228-.153.249.249 0 0 1 .134-.322c.054-.02 5.492-2.216 10.213-2.216.139 0 .247.109.247.248a.244.244 0 0 1-.247.247c-4.622 0-9.976 2.156-10.025 2.181a.215.215 0 0 1-.094.02v-.005ZM363.335 287.79a.249.249 0 0 1-.228-.149.25.25 0 0 1 .129-.326c.257-.109 6.362-2.686 10.569-2.349.139.01.237.128.227.267a.242.242 0 0 1-.266.227c-4.089-.326-10.278 2.285-10.338 2.315a.215.215 0 0 1-.098.02l.005-.005Z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M119.487 235.938v-7.868a6.052 6.052 0 0 0-6.051-6.054h-3.495a6.052 6.052 0 0 0-6.051 6.054v7.868"
        />
        <path
          fill="#1850AF"
          d="M104.735 231.828h.351v8.225h-.351a3.146 3.146 0 0 1-3.144-3.145v-1.939a3.147 3.147 0 0 1 3.144-3.146v.005ZM118.087 240.053h-.351v-8.225h.351a3.146 3.146 0 0 1 3.144 3.146v1.939a3.145 3.145 0 0 1-3.144 3.145v-.005Z"
        />
        <path
          fill="#3EAAFF"
          d="M15.043 210.344c8.309 0 15.044-6.738 15.044-15.05 0-8.312-6.735-15.05-15.044-15.05-8.308 0-15.043 6.738-15.043 15.05 0 8.312 6.735 15.05 15.043 15.05Z"
        />
        <path
          fill="#fff"
          d="M162.548 206.885a5.192 5.192 0 1 0-10.245-1.665 5.192 5.192 0 0 0 10.245 1.665ZM153.687 213.292h7.302a5.995 5.995 0 0 1 5.991 5.994v2.799h-19.285v-2.799a5.995 5.995 0 0 1 5.992-5.994Z"
        />
        <path
          fill="#AAA"
          d="M425.733 209.731c7.877 0 14.262-6.389 14.262-14.269s-6.385-14.269-14.262-14.269c-7.877 0-14.262 6.389-14.262 14.269s6.385 14.269 14.262 14.269Z"
        />
        <path
          stroke="#050505"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M415.732 194.972s8.904-10.109 19.745 0"
        />
        <path
          stroke="#050505"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M418.189 197.678s6.694-7.498 14.836 0M422.628 202.099s2.689-3.447 5.957 0"
        />
        <path
          stroke="#050505"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M420.493 199.908s4.613-5.025 10.224 0"
        />
        <path
          fill="#1E1E1E"
          d="M111.819 73.757h23.641v-14.66h-23.641v14.66Zm7.544-5.218h4.652a.331.331 0 0 1 0 .662h-4.652a.331.331 0 0 1 0-.662Zm-5.388-4.724a.331.331 0 0 1-.069-.465.33.33 0 0 1 .464-.069l3.752 2.8a.338.338 0 0 1 .134.267c0 .103-.05.203-.134.267l-3.752 2.8a.324.324 0 0 1-.198.063.331.331 0 0 1-.197-.598l3.396-2.537-3.396-2.538v.01ZM111.819 58.44h23.641v-1.445h-23.641v1.444Zm2.774-.97s.029-.03.049-.04l.059-.03c.02-.01.04-.014.06-.019a.28.28 0 0 1 .128 0c.02.005.045.01.065.02.019.01.039.02.054.03a.327.327 0 0 1 .143.272c0 .02 0 .044-.005.064 0 .02-.01.044-.019.064-.01.02-.02.04-.03.054-.015.02-.025.035-.04.05a.31.31 0 0 1-.108.07.374.374 0 0 1-.129.024c-.02 0-.044 0-.064-.005-.02 0-.04-.01-.059-.02l-.06-.03a.32.32 0 0 1-.148-.272c0-.088.034-.173.099-.232h.005Zm-1.147 0a.312.312 0 0 1 .296-.089c.02.005.045.01.065.02.019.01.039.02.054.03a.327.327 0 0 1 .143.272c0 .02 0 .044-.005.064 0 .02-.009.044-.019.064-.01.02-.02.04-.03.054-.015.02-.025.035-.04.05a.293.293 0 0 1-.049.04l-.054.03a.251.251 0 0 1-.065.019c-.019.005-.044.005-.064.005-.02 0-.044 0-.064-.005-.02 0-.04-.01-.059-.02l-.06-.03a.347.347 0 0 1-.138-.208c0-.019-.005-.044-.005-.063 0-.09.034-.174.099-.233h-.005Zm-1.147 0s.03-.03.049-.04l.06-.03c.019-.01.039-.014.059-.019a.28.28 0 0 1 .128 0c.02.005.045.01.065.02.02.01.039.02.054.03a.314.314 0 0 1 .148.272c0 .02 0 .044-.005.064 0 .02-.009.044-.019.064-.01.02-.02.04-.03.054-.015.02-.025.035-.044.05a.194.194 0 0 1-.05.04l-.054.03a.251.251 0 0 1-.065.019c-.019.005-.044.005-.064.005a.327.327 0 0 1-.272-.144.145.145 0 0 1-.029-.054.243.243 0 0 1-.02-.064c0-.02-.005-.045-.005-.065 0-.088.035-.173.099-.232h-.005Z"
        />
        <path
          fill="#fff"
          d="M135.791 56.328h-24.303a.332.332 0 0 0-.331.33v17.43c0 .183.149.331.331.331h24.303a.331.331 0 0 0 .331-.331v-17.43a.331.331 0 0 0-.331-.33Zm-.331 17.428h-23.64V59.097h23.64v14.66Zm0-15.317h-23.64v-1.444h23.64v1.444Z"
        />
        <path
          fill="#fff"
          d="M112.225 57.831s.02.04.03.054c.01.02.024.035.039.05.06.06.144.094.233.094.019 0 .044 0 .064-.005a.16.16 0 0 0 .064-.02c.02-.01.04-.02.054-.03.02-.014.035-.024.05-.04.015-.014.03-.034.044-.049l.03-.054c.01-.02.015-.04.02-.064.005-.02.005-.045.005-.065a.315.315 0 0 0-.099-.232.194.194 0 0 0-.05-.04l-.054-.03a.24.24 0 0 0-.064-.019.284.284 0 0 0-.188.02l-.059.03a.194.194 0 0 0-.05.039.315.315 0 0 0-.099.232c0 .02 0 .045.005.065 0 .02.01.044.02.064h.005ZM113.372 57.831s.02.04.03.054c.01.02.025.035.04.05.014.015.029.03.049.04l.059.03a.281.281 0 0 0 .124.024c.02 0 .044 0 .064-.005.02 0 .045-.01.065-.02.019-.01.039-.02.054-.03.02-.014.035-.024.049-.04a.342.342 0 0 0 .04-.049l.03-.054a.285.285 0 0 0 .019-.064c.005-.02.005-.045.005-.065a.326.326 0 0 0-.143-.272l-.054-.03a.338.338 0 0 0-.361.07.311.311 0 0 0-.099.233c0 .02 0 .044.005.064.005.02.01.044.019.064h.005ZM114.642 57.98l.06.03c.019.009.039.014.059.019.02.005.045.005.064.005.045 0 .084-.01.129-.025a.38.38 0 0 0 .109-.07.422.422 0 0 0 .039-.049l.03-.054c.01-.02.015-.04.02-.064 0-.02.005-.045.005-.065a.33.33 0 0 0-.094-.232.346.346 0 0 0-.05-.04l-.054-.03a.256.256 0 0 0-.064-.019.284.284 0 0 0-.188.02l-.06.03a.335.335 0 0 0-.148.272c0 .089.035.172.099.232.015.015.03.03.049.04h-.005Z"
        />
        <path
          fill="#8CCCFF"
          d="M113.906 69.35a.323.323 0 0 0 .267.133c.069 0 .139-.02.198-.064l3.752-2.8a.338.338 0 0 0 .134-.266.338.338 0 0 0-.134-.267l-3.752-2.8a.332.332 0 1 0-.396.534l3.397 2.538-3.397 2.537a.332.332 0 0 0-.069.465v-.01ZM119.363 69.201h4.652a.332.332 0 1 0 0-.662h-4.652a.332.332 0 0 0 0 .662Z"
        />
        <path
          fill="#fff"
          d="m24.881 194.869-4.884-3.645a.534.534 0 0 0-.638.855l4.311 3.215-4.31 3.215a.533.533 0 0 0-.109.746.537.537 0 0 0 .43.213.537.537 0 0 0 .32-.104l4.885-3.645a.539.539 0 0 0 0-.86l-.005.01ZM6.422 195.294l4.31-3.215a.532.532 0 0 0 .11-.747.531.531 0 0 0-.747-.108l-4.884 3.645a.539.539 0 0 0 .005.86l4.884 3.645a.533.533 0 0 0 .321.104.537.537 0 0 0 .43-.213.516.516 0 0 0 .1-.395.518.518 0 0 0-.209-.351l-4.31-3.215-.01-.01ZM16.601 190.571a.535.535 0 0 0-.677.341l-2.778 8.433a.539.539 0 0 0 .03.41.519.519 0 0 0 .479.292.538.538 0 0 0 .509-.366l2.778-8.433a.535.535 0 0 0-.341-.677ZM301.001 209.874l-6.758-5.045a.97.97 0 1 0-1.162 1.558l5.71 4.264-5.71 4.263a.967.967 0 0 0-.38.643.984.984 0 0 0 .183.722.985.985 0 0 0 .781.391c.212 0 .415-.07.578-.193l6.758-5.04a.985.985 0 0 0 .39-.777.97.97 0 0 0-.39-.776v-.01ZM281.424 214.914l-5.709-4.263 5.709-4.264a.97.97 0 0 0-.44-1.741.99.99 0 0 0-.721.183l-6.758 5.04a.988.988 0 0 0-.391.782c0 .306.149.598.391.781l6.753 5.04a.954.954 0 0 0 .583.193.986.986 0 0 0 .781-.391.966.966 0 0 0 .183-.722.965.965 0 0 0-.381-.638ZM289.483 203.895l-.074.222.069-.222a.978.978 0 0 0-1.226.618l-3.846 11.662a.95.95 0 0 0 .054.742.965.965 0 0 0 .865.534.972.972 0 0 0 .925-.668l3.846-11.662a.971.971 0 0 0-.618-1.226h.005Z"
        />
      </g>
      <defs>
        <linearGradient id="rfb" x1={68.429} x2={68.429} y1={0} y2={65.932} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9EDFF" />
          <stop offset={0.13} stopColor="#D9EDFF" stopOpacity={0.98} />
          <stop offset={0.27} stopColor="#DCEEFF" stopOpacity={0.93} />
          <stop offset={0.41} stopColor="#E0F0FF" stopOpacity={0.84} />
          <stop offset={0.56} stopColor="#E6F3FF" stopOpacity={0.72} />
          <stop offset={0.72} stopColor="#EDF6FF" stopOpacity={0.57} />
          <stop offset={0.87} stopColor="#F6FBFF" stopOpacity={0.38} />
          <stop offset={1} stopColor="#fff" stopOpacity={0.2} />
        </linearGradient>
        <linearGradient id="rfc" x1={362.613} x2={362.613} y1={67.673} y2={145.327} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9EDFF" />
          <stop offset={0.13} stopColor="#D9EDFF" stopOpacity={0.98} />
          <stop offset={0.27} stopColor="#DCEEFF" stopOpacity={0.93} />
          <stop offset={0.41} stopColor="#E0F0FF" stopOpacity={0.84} />
          <stop offset={0.56} stopColor="#E6F3FF" stopOpacity={0.72} />
          <stop offset={0.72} stopColor="#EDF6FF" stopOpacity={0.57} />
          <stop offset={0.87} stopColor="#F6FBFF" stopOpacity={0.38} />
          <stop offset={1} stopColor="#fff" stopOpacity={0.2} />
        </linearGradient>
        <clipPath id="rfa">
          <path fill="#fff" d="M0 0h440v301H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RemoteFirst;
